/* public_layout ------------------------------------------------- */
body {
  background: url('./css_images/website/background-intro.jpg') no-repeat center;
  background-size: cover;
}

.public_layout {
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1200px) {
  .public_layout {
      max-width: 1140px;
  }
}


/* header -------------------------------------------------------- */
.public_layout_header {
  height: 90px;
  background-color: transparent;
  color: var(--grey_font);
  padding: 20px 8px;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.25rem;
}
.public_layout_header img {
  max-height: 60px;
  cursor: pointer;
}
.public_layout_header .btn_search {
  font-size: 30px;;
}
.public_layout_header .btn_search:hover {
  color: var(--gold);
}

/* menu ---------------------------------------------------------- */
.public_layout_header .ant-menu {
  background-color: transparent;
  font-size: 20px;;
}
.public_layout_header .ant-menu .ant-menu-item-active,
.public_layout_header .ant-menu .ant-menu-item-selected,
.public_layout_header .ant-menu .ant-menu-item:hover {
  color: var(--gold);
  border-bottom: 2px solid var(--gold);
}
.public_layout_header .ant-menu .ant-menu-item-selected a,
.public_layout_header .ant-menu .ant-menu-item a:hover {
  color: var(--gold);
}

/* content ------------------------------------------------------- */
.public_layout_content {
  background-color: transparent;
  display: flex;
  width: 100%;
}

/* footer -------------------------------------------------------- */
.public_layout_footer {
  background: transparent;
  text-align: center;
  color: white;
  font-size: 16px;
  padding: 0;
}

/* public_page_wrapper ------------------------------------------- */
.public_page_wrapper {
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
