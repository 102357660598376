/* page ---------------------------------------------------------- */
.race_live_page {
  background-color: #262626;
  color: white;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

/* titles & font -------------------------------------------------- */
.race_live_title {
  color: white;
  font-weight: bold;
}
.race_live_subtitle {
  color: white;
  font-weight: bold;
  font-size: 1.8vw;
}
.race_live_prefix {
  color: var(--grey);
  font-weight: normal;
  margin-right: 5px;
}
.race_live_rc {
  background-color: red;
  padding: 5px;
  border-radius: 6px;
  margin-left: 5px;
  margin-right: 5px;
}

/* 1600px to more */
@media screen and (min-width: 1601px) {
  .race_live_component .race_live_title {
    font-size: 30px;
  }
  .race_info_title {
    font-size: 20px;
  }  
}

/* 701px to 1600px */
@media screen and (min-width: 701px) and (max-width: 1600px) {
  .race_live_component .race_live_title {
    font-size: 2vw;
  }
  .race_info_title {
    font-size: 20px;
  }  
}

/* 700px wide or less */
@media screen and (max-width: 700px) {
  .race_live_component .race_live_title {
    font-size: 3vw;
    text-align: center;
  }
  .race_info_title {
    font-size: 15px;
  }  
}

/* header -------------------------------------------------------- */
.race_live_header {
  height: 30px;
  display: flex;
  justify-content: space-between;
}

/* race_live_overview ------------------------------------------ */
.race_live_overview {
  display: flex;
  margin-top: 30px;
  /* margin-bottom: 30px; */
}

.race_live_info {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.race_info_title {
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--gold);
}
.race_ranking_top5 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.race_ranking_top5 .title {
  font-size: 2vw;
  font-weight: bold;
  background: var(--dark_grey);
  width: 100%;
  text-align: center;
  color: var(--gold);
}
.race_ranking_top5 .table {
  border: 3px solid var(--gold);
  border-radius: 5px;
  padding: 5px;
  background-color: #000;
}
.race_ranking_top5 .table .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: black;
}
.race_ranking_top5 .table .ant-table-thead {
  border: 0px solid white;
  border-collapse: collapse;
}
.race_ranking_top5 .table .ant-table-thead .ant-table-cell {
  color: white;
  background-color: black;
  font-size: 12px;
  font-weight: normal;
  padding: 0;
  margin: 0;
  text-align: center;
}
.race_ranking_top5 .table .ant-table-tbody .ant-table-cell {
  background-color: black;
  font-size: 14px;
  font-weight: normal;
  color: white;
  border: 0px;
  padding: 5px 0 0 0;
}

.race_ranking_top5 .table .ant-table-tbody .ant-table-cell .ant-empty {
  visibility: hidden;
}
.race_ranking_top5 .table .ant-table-tbody .ant-table-cell:hover {
  background-color: #000;
}
  
.race_ranking_top5 .table .column-rank {
  text-align: center;
  width: 35px;
  min-width: 35px;
  max-width: 45px;
}
.race_ranking_top5 .table .column-runner-number {
  text-align: center;
  width: 35px;
  min-width: 35px;
  max-width: 45px;
}
.race_ranking_top5 .table .column-runner-name {
  text-align: left;
  font-weight: bold;
  /* width: 40%; */
}
.race_ranking_top5 .table .column-tofirst {
  text-align: center;
  width: 60px;
  min-width: 60px;
  max-width: 70px;
}
.race_ranking_top5 .table .column-time {
  text-align: center;
  width: 60px;
  min-width: 60px;
  max-width: 70px;
}
.race_ranking_top5 .table .column-maxspeed {
  text-align: center;
  width: 60px;
  min-width: 60px;
  max-width: 70px;
}
.top5_tooltip .ant-tooltip-content {
  background-color: white;
}

/* race_progression ---------------------------------------------- */
.race_progression {
  width: 20%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.race_progression .race_progression_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* width: 10vw; */
}
.race_progression .race_progression_wrapper .title {
  font-size: 2vw;
}
.race_progression .race_progression_wrapper .value {
  background: var(--dark_grey);
  color: var(--gold);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vw;
  font-weight: bold;
  /* height: 7vw; */
  border: 0px solid var(--gold);
}

/* race_chooser ---------------------------------------------- */
.race_chooser {
  width: 20%;
  /* padding: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.race_chooser .race_chooser_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
}
.race_chooser .race_chooser_wrapper .title {
  font-size: 2vw;
}
.race_chooser .race_chooser_wrapper .values {
  color: var(--gold);
  width: 100%;
  font-size: 2vw;
  font-weight: bold;
  max-height: 180px;
  overflow-y: auto;
}
.race_chooser .race_chooser_wrapper .values .value {
  display: block;
  min-width: 100px;
}
.race_chooser .race_chooser_wrapper .values .value .code {
  display: inline;
}
.race_chooser .race_chooser_wrapper .values .value .source::before {
  content: '(';
}
.race_chooser .race_chooser_wrapper .values .value .source::after {
  content: ')';
}
.race_chooser .race_chooser_wrapper .values .value .source {
  display: inline;
  font-size: 1.5vw;
}

/* countdown_block ----------------------------------------------- */
.countdown_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.countdown_block span.label {
  font-size: 25px;  
}

/* sec_block ----------------------------------------------------- */
.race_live_body_wrapper {
  display: flex;
  width: 100%;
  /* border: 1px solid var(--gold); */
  padding: 5px;
  min-height: 400px;
  background-color: black;
  justify-content: space-between;
  overflow: hidden;
}
.race_live_sectionals {
  display: flex;
  width: calc(100% - 310px);
  border: 1px solid rgb(53, 52, 52);
  overflow: auto;
}

.race_live_sectional {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid white;
  width: 310px;
}
.sec_block {
  width: 300px;
}
.sectional_title {
  width: 100%;
  height: 30px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
.sec_block .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: black;
}
.sec_block .ant-table-thead {
  border: 0px solid white;
  border-collapse: collapse;
}
.sec_block .ant-table-thead .ant-table-cell {
  color: white;
  background-color: black;
  font-size: 12px;
  font-weight: normal;
  padding: 0;
  margin: 0;
  text-align: center;
}
.sec_block .ant-table-tbody .ant-table-cell {
  background-color: black;
  font-size: 14px;
  font-weight: normal;
  color: white;
  border: 0px;
  padding: 5px 0 0 0;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-rank,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-rank {
  width: 20px;
  text-align: center;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-num,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-num {
  width: 20px;
  text-align: center;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-timefirst,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-timefirst {
  width: 40px;
  text-align: center;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-time,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-time {
  width: 40px;
  text-align: center;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-maxspeed,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-maxspeed {
  width: 40px;
  text-align: center;
}
/* .sec_block .ant-table-tbody .ant-table-placeholder {
  display: none;
  visibility: hidden;
} */

/* sec_block live ------------------------------------------------ */
.sec_block_pc_live {
  width: 100%;
  background-color: red;
  height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.race_live_sectional.live {
  background-color: white;
  border-right: 1px solid white;
  width: 350px;
  border: 1px solid white;
  height: 100%;
}
.sec_block.live {
  padding-bottom: 10px;
  width: 350px;
}
.sec_block.live .ant-table-thead .ant-table-cell {
  background-color: white;
  font-size: 12px;
  font-weight: bold;
  color: black;
}
.sec_block.live .ant-table-tbody .ant-table-cell {
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  color: black;
}
.sec_block.live .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: white;
}

/* sec_block finish ------------------------------------------------ */
.sec_block_pc_finish {
  width: 100%;
  background-color: green;
  height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.race_live_sectional.finish {
  background-color: white;
  border-right: 1px solid white;
  width: 350px;
  border: 1px solid white;
  height: 100%;
}
.sec_block.finish {
  padding-bottom: 10px;
  width: 350px;
}
.sec_block.finish .ant-table-thead .ant-table-cell {
  background-color: white;
  font-size: 12px;
  font-weight: bold;
  color: black;
}
.sec_block.finish .ant-table-tbody .ant-table-cell {
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  color: black;
}
.sec_block.finish .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: white;
}

/* race_live_footer ---------------------------------------------- */
.race_live_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* next_race ----------------------------------------------------- */
/* .next_race {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.next_race h2 {
  font-size: 18px;
} */

/* latency bloc -------------------------------------------------- */
.latency0, .latency1, .latency2, .latency3 {
  width: 300px;
  padding: 5px;
  text-align: center;
  border-radius: 10px;
}
.latency0 {
  background-color: green;
}
.latency1 {
  background-color: orange;
}
.latency2 {
  background-color: rgb(211, 121, 4);
}
.latency3 {
  background-color: red;
}
.latency4 {
  background-color: red;
}
