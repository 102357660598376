.inject-meeting-page {
    background-color: #262626;
    color: white
}

.inject-meeting-page .header .danger {
    color: red;
    font-weight: bold
}

.inject-meeting-page .header .information {
    color: cyan;
    font-weight: bold
}

.inject-meeting-page .body {
    min-height: 180px;
}
