@import url(https://fonts.googleapis.com/css?family=Orbitron);
.user-page {
    background-color: var(--dark_grey);
    color: white;
    --label-width: 150px;
    --label-padding: 5px;
    --column-right-width: 120px;
    --nb-column-right: 8;
    --right-disabled-bgcolor: var(--dark_grey);
    --right-disabled-color: var(--grey);
    --right-unactivate-bgcolor: brown;
    --right-unactivate-color: white; 
    --right-activate-today-bgcolor: green;
    --right-activate-today-color: white;
    --right-activate-other-day-bgcolor: orange;
    --right-activate-other-day-color: white;
    --right-selected-border-radius: 30px;

    --users-data-form-width: 350px;
    --sources-width : calc( var(--label-width) + (var(--column-right-width) * var(--nb-column-right)));
}

.user-page .user-form {
}
.user-page .user-form .user-data-form {
    display: inline-block;
    width: var(--users-data-form-width);
    vertical-align: top;
}
.user-page .user-form .user-form-multidate {
    display: inline-block;
    width: calc( var(--sources-width) - var(--users-data-form-width) );

    vertical-align: bottom;
    padding-bottom: 10px;
    text-align: right;
}
.user-page .user-form .user-form-multidate .user-form-multidate-selections {
    text-align: left;
    max-width: 380px;
    margin-left: auto;
}
.user-page .user-form .user-data-form .user-data-input {
    margin: 10px 0px;
}
.user-page .user-form .user-data-form .user-data-input .label {
    display: inline-block;
    width: var(--label-width);
    text-align: right;
    padding-right: var(--label-padding);
}
.user-page .user-form .user-data-form .user-data-input .label:after {
    content: ':';
}
.user-page .user-form .user-data-form .user-data-input .input {
    display: inline-block;
}
.user-page .user-form .user-data-form .user-data-input .input input {
    background-color: var(--black);
    color: white;
}
.user-page .user-form .user-sources-form {
    width: var(--sources-width);
}
.user-page .user-form .user-sources-form .user-sources-table {
    margin-top: 10px;
}
.user-page .user-form .user-sources-form .user-sources-table .user-sources-header {
    text-align: center;
}
.user-page .user-form .user-sources-form .user-sources-table .user-sources-header .right-level-1 {
    border-top : 1px solid white;
}
.user-page .user-form .user-sources-form .user-sources-table .user-sources-header .right-level-1,
.user-page .user-form .user-sources-form .user-sources-table .user-sources-header .right-level-2,
.user-page .user-form .user-sources-form .user-sources-table .user-sources-header .right-level-3 {
    border-right : 1px solid white;
    border-left : 1px solid white;
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form {
    height: 70px;
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-label {
    width: var(--label-width);
    text-align: right;
    padding-right: var(--label-padding);
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-label:after {
    content: ':';
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input {
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-top: 1px dotted white;
    border-bottom: 1px dotted white;
    width: var(--column-right-width);
    vertical-align: top;
    padding: 0px 10px;
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input.disabled {
    background-color: var(--right-disabled-bgcolor);
    color: var(--right-disabled-color);
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input.activate.other-period {
    background-color: var(--right-activate-other-day-bgcolor);
    color: var(--right-activate-other-day-color);
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input.activate.actual-period {
    background-color: var(--right-activate-today-bgcolor);
    color: var(--right-activate-today-color);
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input.unactivate {
    background-color: var(--right-unactivate-bgcolor);
    color: var(--right-unactivate-color);
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input.selected {
    border-radius: var(--right-selected-border-radius);
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input .user-source-switch {
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input .user-source-date-start {
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input .user-source-date-stop {
}
.user-page .user-form .user-form-actions {
    padding-top: 20px;
    text-align: center;
}
.user-page .user-form .user-form-actions .user-form-action {
    display: inline-block;
    padding: 20px;
}
.user-page .user-form .user-form-actions .user-form-action .user-form-button {
    width: 200px;
}

/***************************************************************************************************************************************/

.modal-period-choice .ant-modal-header {
    background-color: var(--dark_grey);
}
.modal-period-choice .ant-modal-header .ant-modal-title,
.modal-period-choice .ant-modal-content .ant-modal-close,
.modal-period-choice .period-choice .date-choice .ant-select .ant-select-arrow {
    color: white;
}

.modal-period-choice .ant-modal-content {
    background-color: var(--dark_grey);
    color: white;
}
.modal-period-choice .period-choice {
}
.modal-period-choice .period-choice .date-choice {
    height: 35px;
}
.modal-period-choice .period-choice .date-choice .label,
.modal-period-choice .period-choice .date-choice .year,
.modal-period-choice .period-choice .date-choice .month,
.modal-period-choice .period-choice .date-choice .date {
    display: inline-block;
}
.modal-period-choice .period-choice .date-choice .label {
    width: 50px;
    text-align: right;
    padding-right: 5px;
}
.modal-period-choice .period-choice .date-choice .label:after {
    content: ':';
}
/* .modal-period-choice .period-choice .date-choice .year .ant-select {
    width: 80px;
} */
/* .modal-period-choice .period-choice .date-choice .year .ant-select .ant-select-selector {
    color: red;
    background-color: var(--black);
} */
.modal-period-choice .period-choice .date-choice .ant-select .ant-select-selector {
    background-color: var(--dark_grey);
    border-color: var(--grey);
    color: white;
}
/*.modal-period-choice .period-choice .date-choice*/ .date-choices-list.ant-select-dropdown {
    background-color: var(--dark_grey);
}
/*.modal-period-choice .period-choice .date-choice*/ .date-choices-list.ant-select-dropdown .ant-select-item {
    background-color: var(--dark_grey);
    color: white;
}
/*.modal-period-choice .period-choice .date-choice*/ .date-choices-list.ant-select-dropdown .ant-select-item.ant-select-item-option-active {
    background-color: var(--grey);
    color: white;
}
.modal-period-choice .period-choice .date-choice .year .ant-select {
    /* background-color: var(--dark_grey); */
    width: 80px;
}
.modal-period-choice .period-choice .date-choice .month .ant-select {
    width: 110px;
}
.modal-period-choice .period-choice .date-choice .date .ant-select {
    width: 60px;
}

/***************************************************************************************************************************************/


:root {
  --gold: #DBAC58;
  --gold_hover: #e0a642;
  --black: #000000;
  --grey: #757575;
  --dark_grey: #262626;
  --green: #77b594;
  --green_hover: #37bb75;
  --red: #d12323;
  --red_hover: #b31111;

  /* public site */
  --grey_bg: #EEEEEE;
  --grey_font: #666666;


  /* font */
  --orbitron_font: 'Orbitron', sans-serif;
  --common_font: 'Roboto', sans-serif;
}
/* page ---------------------------------------------------------- */
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: black;
  height: 100%;
  display: flex;
}
body #root {
  width: 100%;
  
}
a {
  color: inherit;
  text-decoration: none;
}
* {
  box-sizing: border-box;
}


/* button -------------------------------------------------------- */
.ant-btn,
.ant-btn:focus,
.ant-btn:active {
  background-color: var(--gold);
  border: 1px solid var(--gold);
  color: white;
  border-radius: 4px;
  height: 35px;
}
.ant-btn:hover {
  background-color: var(--gold_hover);
  border: 1px solid var(--gold_hover);
  color: white;
}

.ant-btn.success {
  background-color: var(--green);
  border: 1px solid var(--green);
}
.ant-btn.success:hover {
  background-color: var(--green_hover);
  border: 1px solid var(--green_hover);
}

.ant-btn.error {
  background-color: var(--red);
  border: 1px solid var(--red);
}
.ant-btn.error:hover {
  background-color: var(--red_hover);
  border: 1px solid var(--red_hover);
}

/* header -------------------------------------------------------- */
.ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--black);
}
.ant-layout-header img {
  max-height: 50px;
}
.ant-layout-header .ant-menu-dark.ant-menu-horizontal {
  background-color: var(--black);
}
.ant-layout-header .ant-menu.ant-menu-dark .ant-menu-item-selected, 
.ant-layout-header .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-layout-header .ant-menu-dark.ant-menu-horizontal .ant-menu-item:hover {
  background-color: var(--gold);
}

/* layout -------------------------------------------------------- */
.ant-layout {
  height: 100%;
}

/* loader -------------------------------------------------------- */
.ant-spin {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;  
}

/* public_layout ------------------------------------------------- */
body {
  background: url(/static/media/background-intro.62f94856.jpg) no-repeat center;
  background-size: cover;
}

.public_layout {
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1200px) {
  .public_layout {
      max-width: 1140px;
  }
}


/* header -------------------------------------------------------- */
.public_layout_header {
  height: 90px;
  background-color: transparent;
  color: var(--grey_font);
  padding: 20px 8px;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.25rem;
}
.public_layout_header img {
  max-height: 60px;
  cursor: pointer;
}
.public_layout_header .btn_search {
  font-size: 30px;;
}
.public_layout_header .btn_search:hover {
  color: var(--gold);
}

/* menu ---------------------------------------------------------- */
.public_layout_header .ant-menu {
  background-color: transparent;
  font-size: 20px;;
}
.public_layout_header .ant-menu .ant-menu-item-active,
.public_layout_header .ant-menu .ant-menu-item-selected,
.public_layout_header .ant-menu .ant-menu-item:hover {
  color: var(--gold);
  border-bottom: 2px solid var(--gold);
}
.public_layout_header .ant-menu .ant-menu-item-selected a,
.public_layout_header .ant-menu .ant-menu-item a:hover {
  color: var(--gold);
}

/* content ------------------------------------------------------- */
.public_layout_content {
  background-color: transparent;
  display: flex;
  width: 100%;
}

/* footer -------------------------------------------------------- */
.public_layout_footer {
  background: transparent;
  text-align: center;
  color: white;
  font-size: 16px;
  padding: 0;
}

/* public_page_wrapper ------------------------------------------- */
.public_page_wrapper {
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-header-button,
.login-header-button:focus,
.login-header-button:active {
  background-color: transparent;
  border: 2px solid var(--gold);
  color: var(--gold);
  border-radius: 20px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  padding: 0 25px 0 25px;
  display: flex;
  align-items: center;
}
.public_page_wrapper.login {
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  align-items: center;
}
.public_page_wrapper.login h1 {
  margin: 0;
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 4.375rem;
  color: white;
  font-family: Poppins,sans-serif;
  max-width: 780px;
  text-align: center;
}
.public_page_wrapper.login h1 em {
  color: #cda768;
  font-style: normal;
}

.login_overlay {
  width: 80%;
  max-width: 700px;
  display: flex;
  justify-content: center;
  background: transparent;
  border: 0px;
  height: 50vh;
  min-height: 200px;
}
.login_overlay .ant-card-body {
  width: 100%;
  max-width: 500px;
}

.login_form {
  width: 100%;
}
.login_form .ant-input {
  height: 35px;
  width: 100%;
}
.login_form .ant-btn {
  margin-top: 10px;
  height: 50px;
  width: 100%;
  text-transform: uppercase;
  font-size: 20px;
  /* font-family: var(--orbitron_font); */
  border: 0px solid var(--gold);
  background: var(--gold);
  border-radius: 25px;
  color: white;
}
.login_form .ant-form-item-label > label {
  color: white;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1.5em;
}
.login_form .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.login_form .ant-btn,
.login_form .ant-btn:focus,
.login_form .ant-btn:active {
  background-color: var(--gold);
  border: 3px solid var(--gold);
}
.login_form .ant-btn:hover {
  background-color: white;
  border: 0px solid var(--gold);
  color: var(--gold);
}


.login_form .login_input_text .ant-input-affix-wrapper {
  border: 1px solid #565656;
  border-radius: .1875rem;
  padding: 15px;
}
.login_form .login_input_text .ant-input-affix-wrapper,
.login_form .login_input_text .ant-input-affix-wrapper input {
  background-color: #373737;
  color: #fff;  
}
.private_layout .sider {
  background-color: black;
}
.private_layout .sider .logo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.private_layout .sider .logo img {
  width: 90%;
}
/* menu */
.menu_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.private_layout .sider .menu {
  margin-top: 20px;
  background-color: black;
  color: white;
  border: 0px;
}
.private_layout .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
.private_layout .sider .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
  font-size: 25px;
}
.private_layout .sider .menu .ant-menu-item:hover, 
.private_layout .sider .menu .ant-menu-item-active,
.private_layout .sider .menu .ant-menu-item-selected {
  background-color: var(--gold);
  color: white;
}
/* layout */
.private_layout .ant-layout {
  background-color: white;
}

.private_layout_footer {
  text-align: center;
  background-color: #262626;
  padding: 0;
  margin: 0;
  color: white;
  position: fixed;
  bottom: 0;
  left: 50%;
}
.terminal_console { 
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: black;
  background-image: radial-gradient(
    rgba(0, 150, 0, 0.25), black 120%
  );
  color: white;
  font: 1rem Inconsolata, monospace;
  text-shadow: 0 0 5px #C8C8C8;
  opacity: 60%;
  bottom: 0px;
  right: 0px;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  line-height: 25px;
  min-height: 200px;
  max-height: 500px;
  overflow: auto;
}
.terminal_console:after {
  content: "";
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; */
  background: repeating-linear-gradient(
    0deg,
    rgba(black, 0.15),
    rgba(black, 0.15) 1px,
    transparent 1px,
    transparent 2px
  );  
}
.terminal_console p {
  padding: 0px;
  margin: 0px; 
}
/* layout */
.private_page_wrapper {
  display: flex;
  height: 100%;
  overflow: auto;  
  padding: 0px;
  justify-content: flex-start;
  flex-direction: column;
}

/* components */
.private_page_wrapper .header {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.private_page_wrapper .data_list_toolbar {
  display: flex;
  justify-content: space-between;
}

.private_page_wrapper .action_toolbar {
  padding: 10px;
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.private_page_wrapper .data_list {
  background-color: #E5E5E5;
  width: 100%;
  height: 100%;
}

/* titles */
.private_page_wrapper .title {
  text-transform: uppercase;
  font-size: 20px;
  font-family: var(--orbitron_font);
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.private_page_wrapper .subtitle {
  font-size: 14px;
  font-family: var(--common_font);
}

/* form */
.common_form {
  max-width: 700px;
}

/* search_filters */
.search_filters {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;  
  margin: 0px 24px;
  justify-content: flex-start;
}

.search_filters_elems {
  min-width: 300px;
  width: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search_filters_title_div {
  width: 90px;
  min-width: 90px;
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
}
.search_filters_select_div {
  width: 95%;
}
.common_list {
  background-color: #E5E5E5;
  border-collapse: separate;
}
.common_list .ant-table-thead .ant-table-cell {
  background-color: #E3E9EE;
  border: 0px solid #DDDDDD;
}

/* col action */
.common_list th.col_action,
.common_list td.col_action {
  text-align: center;
}
.common_list .ant-btn-circle {
  border-radius: 50%;
  margin-right: 3px;
}

/* icones */
.record_active {
  color: green;
  font-size: 25px;
}
.record_disabled {
  color: red;
  font-size: 25px;
}
.record_admin {
  color: var(--gold);
  font-size: 25px;
}

.form_toolbar .ant-form-item-control-input-content button {
  margin-right: 5px;
}
.settings_toolbar {
  display: flex;
  padding: 5px;
}

.settings_toolbar .ant-btn {
  margin-right: 5px;
}
.external_data_tabs {
  padding: 20px;
}
/* page ---------------------------------------------------------- */
.race_timeline_wrapper {
  padding: 20px;
  margin: 0px;
  height: 100%;
  position: relative;
  overflow: auto;
  background-color: #262626;
  color: white;
}

/* header -------------------------------------------------------- */
.race_timeline_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  flex-grow: 1;
  padding: 10px;
}
.race_timeline_header h1 {
  color: white;
}
/* header right */
.race_timeline_header_clock {
  display: flex;
}
.race_timeline_header .btn_live {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 10px;
  width: 70px;
  background-color: var(--red);
  font-weight: bold;
  color: white;
}
.race_timeline_header .btn_live:hover {
  background-color: var(--red_hover);
  font-weight: bold;
  color: white;
}
.race_timeline_header_clock span {
  margin: 0 10px 0 15px;
  font-size: 18px;
  font-weight: bold;
}

/* timeline ------------------------------------------------------ */
.race_timeline_content {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  background-color: black;
  border: 1px solid #262626;
}
.race_timeline_hours {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
}
.race_timeline_hours .hour_label {
  height: 60px;
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-right: 1px solid #262626;
}
.race_timeline_hours .hour_label:not(:last-child) {
  border-bottom: 1px solid #262626;
}
.race_timeline_wrapper .time_line {
  height: 2px;
  background-color: red;
  position: absolute;
  width: calc(100% - 140px);
  left: 120px;
}
.race_timeline_header_meeting {
  padding-left: 105px;
  height: 50px;
  display: flex;
}
.race_timeline_header_meeting .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  font-weight: bold;
  background-color: black;
  border-right: 1px solid #262626;
  height: 49px;
}
.race_timeline_meeting {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #262626;
}
.race_block {
  width: 100px;
  height: 25px;
  background-color: #666;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
}
.race_block.active {
  background-color: red;
}


/* popover */
.popover_race_number {
  background-color: red;
  color: white;
  padding: 4px;
  margin-right: 5px;
}
.popover_content_wrapper .race_name {
  font-weight: bold;
}
.popover_content_wrapper p {
  display: flex;
  justify-content: flex-start;
}
.popover_content_wrapper .p_icon {
  width: 30px;
  display: flex;
  justify-content: flex-start;
}
.popover_content_wrapper .p_icon img {
  width: 20px;
}
.popover_content_wrapper .p_label {
  width: 100px;
  display: flex;
  justify-content: flex-start;
}
.popover_content_wrapper .p_val {
  display: flex;
  justify-content: flex-start;
}

/* page ---------------------------------------------------------- */
.race_live_page {
  background-color: #262626;
  color: white;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

/* titles & font -------------------------------------------------- */
.race_live_title {
  color: white;
  font-weight: bold;
}
.race_live_subtitle {
  color: white;
  font-weight: bold;
  font-size: 1.8vw;
}
.race_live_prefix {
  color: var(--grey);
  font-weight: normal;
  margin-right: 5px;
}
.race_live_rc {
  background-color: red;
  padding: 5px;
  border-radius: 6px;
  margin-left: 5px;
  margin-right: 5px;
}

/* 1600px to more */
@media screen and (min-width: 1601px) {
  .race_live_component .race_live_title {
    font-size: 30px;
  }
  .race_info_title {
    font-size: 20px;
  }  
}

/* 701px to 1600px */
@media screen and (min-width: 701px) and (max-width: 1600px) {
  .race_live_component .race_live_title {
    font-size: 2vw;
  }
  .race_info_title {
    font-size: 20px;
  }  
}

/* 700px wide or less */
@media screen and (max-width: 700px) {
  .race_live_component .race_live_title {
    font-size: 3vw;
    text-align: center;
  }
  .race_info_title {
    font-size: 15px;
  }  
}

/* header -------------------------------------------------------- */
.race_live_header {
  height: 30px;
  display: flex;
  justify-content: space-between;
}

/* race_live_overview ------------------------------------------ */
.race_live_overview {
  display: flex;
  margin-top: 30px;
  /* margin-bottom: 30px; */
}

.race_live_info {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.race_info_title {
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--gold);
}
.race_ranking_top5 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.race_ranking_top5 .title {
  font-size: 2vw;
  font-weight: bold;
  background: var(--dark_grey);
  width: 100%;
  text-align: center;
  color: var(--gold);
}
.race_ranking_top5 .table {
  border: 3px solid var(--gold);
  border-radius: 5px;
  padding: 5px;
  background-color: #000;
}
.race_ranking_top5 .table .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: black;
}
.race_ranking_top5 .table .ant-table-thead {
  border: 0px solid white;
  border-collapse: collapse;
}
.race_ranking_top5 .table .ant-table-thead .ant-table-cell {
  color: white;
  background-color: black;
  font-size: 12px;
  font-weight: normal;
  padding: 0;
  margin: 0;
  text-align: center;
}
.race_ranking_top5 .table .ant-table-tbody .ant-table-cell {
  background-color: black;
  font-size: 14px;
  font-weight: normal;
  color: white;
  border: 0px;
  padding: 5px 0 0 0;
}

.race_ranking_top5 .table .ant-table-tbody .ant-table-cell .ant-empty {
  visibility: hidden;
}
.race_ranking_top5 .table .ant-table-tbody .ant-table-cell:hover {
  background-color: #000;
}
  
.race_ranking_top5 .table .column-rank {
  text-align: center;
  width: 35px;
  min-width: 35px;
  max-width: 45px;
}
.race_ranking_top5 .table .column-runner-number {
  text-align: center;
  width: 35px;
  min-width: 35px;
  max-width: 45px;
}
.race_ranking_top5 .table .column-runner-name {
  text-align: left;
  font-weight: bold;
  /* width: 40%; */
}
.race_ranking_top5 .table .column-tofirst {
  text-align: center;
  width: 60px;
  min-width: 60px;
  max-width: 70px;
}
.race_ranking_top5 .table .column-time {
  text-align: center;
  width: 60px;
  min-width: 60px;
  max-width: 70px;
}
.race_ranking_top5 .table .column-maxspeed {
  text-align: center;
  width: 60px;
  min-width: 60px;
  max-width: 70px;
}
.top5_tooltip .ant-tooltip-content {
  background-color: white;
}

/* race_progression ---------------------------------------------- */
.race_progression {
  width: 20%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.race_progression .race_progression_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* width: 10vw; */
}
.race_progression .race_progression_wrapper .title {
  font-size: 2vw;
}
.race_progression .race_progression_wrapper .value {
  background: var(--dark_grey);
  color: var(--gold);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vw;
  font-weight: bold;
  /* height: 7vw; */
  border: 0px solid var(--gold);
}

/* race_chooser ---------------------------------------------- */
.race_chooser {
  width: 20%;
  /* padding: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.race_chooser .race_chooser_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
}
.race_chooser .race_chooser_wrapper .title {
  font-size: 2vw;
}
.race_chooser .race_chooser_wrapper .values {
  color: var(--gold);
  width: 100%;
  font-size: 2vw;
  font-weight: bold;
  max-height: 180px;
  overflow-y: auto;
}
.race_chooser .race_chooser_wrapper .values .value {
  display: block;
  min-width: 100px;
}
.race_chooser .race_chooser_wrapper .values .value .code {
  display: inline;
}
.race_chooser .race_chooser_wrapper .values .value .source::before {
  content: '(';
}
.race_chooser .race_chooser_wrapper .values .value .source::after {
  content: ')';
}
.race_chooser .race_chooser_wrapper .values .value .source {
  display: inline;
  font-size: 1.5vw;
}

/* countdown_block ----------------------------------------------- */
.countdown_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.countdown_block span.label {
  font-size: 25px;  
}

/* sec_block ----------------------------------------------------- */
.race_live_body_wrapper {
  display: flex;
  width: 100%;
  /* border: 1px solid var(--gold); */
  padding: 5px;
  min-height: 400px;
  background-color: black;
  justify-content: space-between;
  overflow: hidden;
}
.race_live_sectionals {
  display: flex;
  width: calc(100% - 310px);
  border: 1px solid rgb(53, 52, 52);
  overflow: auto;
}

.race_live_sectional {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid white;
  width: 310px;
}
.sec_block {
  width: 300px;
}
.sectional_title {
  width: 100%;
  height: 30px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
.sec_block .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: black;
}
.sec_block .ant-table-thead {
  border: 0px solid white;
  border-collapse: collapse;
}
.sec_block .ant-table-thead .ant-table-cell {
  color: white;
  background-color: black;
  font-size: 12px;
  font-weight: normal;
  padding: 0;
  margin: 0;
  text-align: center;
}
.sec_block .ant-table-tbody .ant-table-cell {
  background-color: black;
  font-size: 14px;
  font-weight: normal;
  color: white;
  border: 0px;
  padding: 5px 0 0 0;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-rank,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-rank {
  width: 20px;
  text-align: center;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-num,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-num {
  width: 20px;
  text-align: center;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-timefirst,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-timefirst {
  width: 40px;
  text-align: center;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-time,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-time {
  width: 40px;
  text-align: center;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-maxspeed,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-maxspeed {
  width: 40px;
  text-align: center;
}
/* .sec_block .ant-table-tbody .ant-table-placeholder {
  display: none;
  visibility: hidden;
} */

/* sec_block live ------------------------------------------------ */
.sec_block_pc_live {
  width: 100%;
  background-color: red;
  height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.race_live_sectional.live {
  background-color: white;
  border-right: 1px solid white;
  width: 350px;
  border: 1px solid white;
  height: 100%;
}
.sec_block.live {
  padding-bottom: 10px;
  width: 350px;
}
.sec_block.live .ant-table-thead .ant-table-cell {
  background-color: white;
  font-size: 12px;
  font-weight: bold;
  color: black;
}
.sec_block.live .ant-table-tbody .ant-table-cell {
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  color: black;
}
.sec_block.live .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: white;
}

/* sec_block finish ------------------------------------------------ */
.sec_block_pc_finish {
  width: 100%;
  background-color: green;
  height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.race_live_sectional.finish {
  background-color: white;
  border-right: 1px solid white;
  width: 350px;
  border: 1px solid white;
  height: 100%;
}
.sec_block.finish {
  padding-bottom: 10px;
  width: 350px;
}
.sec_block.finish .ant-table-thead .ant-table-cell {
  background-color: white;
  font-size: 12px;
  font-weight: bold;
  color: black;
}
.sec_block.finish .ant-table-tbody .ant-table-cell {
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  color: black;
}
.sec_block.finish .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: white;
}

/* race_live_footer ---------------------------------------------- */
.race_live_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* next_race ----------------------------------------------------- */
/* .next_race {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.next_race h2 {
  font-size: 18px;
} */

/* latency bloc -------------------------------------------------- */
.latency0, .latency1, .latency2, .latency3 {
  width: 300px;
  padding: 5px;
  text-align: center;
  border-radius: 10px;
}
.latency0 {
  background-color: green;
}
.latency1 {
  background-color: orange;
}
.latency2 {
  background-color: rgb(211, 121, 4);
}
.latency3 {
  background-color: red;
}
.latency4 {
  background-color: red;
}

.spectator_wrapper {
  background-color: #262626;
  color: white;
  min-height: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  padding: 10px;
}

.spectator_wrapper .race_spectator_subtitle {
  color: var(--gold);
  font-size: 30px;
  margin-top: 15px;
}

.spectator_wrapper .race_spectator_component {
}

/** SWITCHERS ******************************************************************************/

.spectator_wrapper .race_spectator_component .race_spectator_component__switchers {
  display: block;
  position: fixed;
  top: 0px;
  right: 0px;
  border-width: 0px 0px 3px 3px;
  border-style: solid;
  border-color: gold;
  border-radius: 0px 0px 0px 10px;
  padding: 5px;
  background-color: #262626;
}

.spectator_wrapper .race_spectator_component .race_spectator_component__switchers .race_spectator_component__switcher_label {
  display: none;
  margin: 0px 10px;
}

.spectator_wrapper .race_spectator_component .race_spectator_component__switchers:hover .race_spectator_component__switcher_label {
  display: inline-block;
}

/** HEADER ******************************************************************************/
.spectator_wrapper .race_spectator_header {
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.spectator_wrapper .race_spectator_header > .race_spectator_title {
  color: white;
  font-weight: bold;
}
.spectator_wrapper .race_spectator_header > .race_spectator_title > .race_spectator_prefix {
  color: var(--grey);
  font-weight: normal;
  margin-right: 5px;
}
.spectator_wrapper .race_spectator_header > .race_spectator_title > .race_spectator_rc {
  background-color: red;
  padding: 5px;
  border-radius: 6px;
  margin-left: 5px;
  margin-right: 5px;
}
.spectator_wrapper .race_spectator_header > .race_spectator_title > .race_spectator_distance {
  margin-left: 20px;
  color: var(--gold);
}
.spectator_wrapper .race_spectator_header > .race_spectator_title > .race_spectator_discipline {
  margin-left: 5px;
  color: var(--gold);
}
.spectator_wrapper .race_spectator_header > .race_spectator_title > .race_spectator_countdown {
  margin-left: 5px;
  color: var(white);
}

/** /HEADER ******************************************************************************/


/** PERF / DATA ******************************************************************************/

.race_spectator_performances {
}

.race_spectator_performances .race_spectator_performances_block {
  display: inline-block;
}

.race_spectator_performances .race_spectator_performances_data {
  margin-right: 20px;
  vertical-align: top;
}

.race_spectator_performances .race_spectator_performances_data table {
}

.race_spectator_performances .race_spectator_performances_data table thead {
}

.race_spectator_performances .race_spectator_performances_data table thead tr {
}

.race_spectator_performances .race_spectator_performances_data table thead tr th {
  border: 1px solid white;
}
.race_spectator_performances .race_spectator_performances_data table thead tr th.competitors {
  border-top: 0px solid white;
  border-left: 0px solid white;
}
.race_spectator_performances .race_spectator_performances_data table thead tr th.separate {
  border-top-width: 0px;
  border-bottom-width: 0px;
}



.race_spectator_performances .race_spectator_performances_data table tr .column-competitor {
  width: 25px;
}
.race_spectator_performances .race_spectator_performances_data table tr .column-rank,
.race_spectator_performances .race_spectator_performances_data table tr .column-dst,
.race_spectator_performances .race_spectator_performances_data table tr .column-speed,
.race_spectator_performances .race_spectator_performances_data table tr .column-tofirst,
.race_spectator_performances .race_spectator_performances_data table tr .column-sect-rank,
.race_spectator_performances .race_spectator_performances_data table tr .column-sect-speed,
.race_spectator_performances .race_spectator_performances_data table tr .column-sect-time {
  width: 90px;
}
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd .column-sect-time.best,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even .column-sect-time.best {
  background-color: lime;
  font-weight: bolder;
}
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd .column-sect-time.worst,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even .column-sect-time.worst {
  /* color: red; */
}
.race_spectator_performances .race_spectator_performances_data table tr .separate {
  width: 20px;
}

.race_spectator_performances .race_spectator_performances_data table tbody tr {
}

.race_spectator_performances .race_spectator_performances_data table tbody tr td.cell-waiting {
  border: 1px solid white;
  text-align: center;
  font-size: xxx-large;
}

.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even td {
  border: 1px solid white;
  background-color: white;
  color: black;
}
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd td {
  border: 1px solid white;
  background-color: lightgrey;
  color: black;
}
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even.competitor-first td.column-rank,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even.competitor-first td.column-dst,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even.competitor-first td.column-speed,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even.competitor-first td.column-tofirst,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd.competitor-first td.column-rank,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd.competitor-first td.column-dst,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd.competitor-first td.column-speed,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd.competitor-first td.column-tofirst {
  border: 1px solid yellow;
  background-color: yellow;
  color: black;
}

.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even td.separate,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd td.separate {
  border-top-width: 0px;
  border-bottom-width: 0px;
  background-color: transparent;
}

.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-competitor,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-rank,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-dst,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-speed,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-tofirst,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-sect-rank,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-sect-speed,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-sect-time {
  text-align: center;
}

.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-competitor .competitor-jersey {
  border-radius: 12.5px;
  border-width: 2px;
  border-style: solid;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-tofirst.near-first {
  color: red;
}
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-sect-rank.big-loose {
  color: red;
}
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-sect-rank.big-win {
  color: green;
}

.race_spectator_performances .race_spectator_performances_map {
}

.race_spectator_performances .race_spectator_performances_map .race_spectator_performances_map_waiting {
  width: 400px;
  height: 300px;
  border: 1px solid white;
  vertical-align: middle;
  text-align: center;
  line-height: 300px;
  font-size: xx-large;
}

/** /PERF / DATA ******************************************************************************/


/** DISTANCE to FIRST ******************************************************************************/
.race_spectator_distance_to_first {
}
.race_spectator_distance_to_first > div {
  /* border: 1px solid cyan; */
  display: flex;
}
.race_spectator_distance_to_first > div > .race_spectator_subtitle {
  /* border: 1px solid white; */
}
.race_spectator_distance_to_first > div > .race_spectator_dtf_block {
  display: inline-block;
}
.race_spectator_distance_to_first > div > .race_spectator_dtf_block.race_spectator_dtf_herd {
  /* border: 1px solid red; */
}
.race_spectator_distance_to_first > div > .race_spectator_dtf_block.race_spectator_dtf_progress {
  /* border: 1px solid lime; */
}
/** / DISTANCE to FIRST ******************************************************************************/

/** DISTANCE to FIRST / HERD ******************************************************************************/
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line {
  /* border: 1px solid pink; */
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .title {
  display: inline-block;
  padding-right: 20px;
  font-style: italic;
  font-size: 11px;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend {
  display: inline-block;
  font-size: 12px;
  border: 1px solid white;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend .item {
  display: inline-block;
  padding: 0px 20px;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend input {
  background-color: var(--grey);
  width: 20px;
  border-width: 0px;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend .speed-delta-0,
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend .speed-delta-1,
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend .speed-delta-2,
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend .speed-delta-3 {
  font-size: 18px;
  vertical-align: middle;
}
.race_spectator_dtf_herd_lines {
  display: flex;
}

.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_horse_lines {
  /* border: 1px solid lime; */
  display: inline-block;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_horse_lines .race_spectator_dtf_herd_horse_line {
  border-bottom: 1px solid grey;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_horse_lines .race_spectator_dtf_herd_horse_line.waiting {
  height: 125px;
  text-align: center;
  vertical-align: middle;
  font-size: xxx-large;
  line-height: 125px;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_horse_lines .race_spectator_dtf_herd_horse_line .competitor-jersey {
  border-radius: 12.5px;
  border-width: 2px;
  border-style: solid;
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .speed-delta-0 {
  color: transparent;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .speed-delta-1 {
  color: blue;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .speed-delta-2 {
  color: gold;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .speed-delta-3 {
  color: red;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_final {
  display: inline-block;
  /* border: 1px solid tan; */
  border-left: 2px dashed white;
}

/** / DISTANCE to FIRST / HERD ******************************************************************************/

/** DISTANCE to FIRST / PROGRESS ******************************************************************************/
.race_spectator_distance_to_first .race_spectator_dtf_progress .race_spectator_progress {
  padding: 70px 10px 0px 10px;
}
.race_spectator_distance_to_first .race_spectator_dtf_progress .race_spectator_progress .race_spectator_progress_pct {
  text-align: center;
  padding-bottom: 10px;
  font-size: 40px;
  color: var(--gold);
}
.race_spectator_distance_to_first .race_spectator_dtf_progress .race_spectator_progress .race_spectator_progress_bar {
  width : 100px;
  height: 10px;
  border: 1px solid var(--gold);
  display: flex;
  box-sizing: content-box;
}
.race_spectator_distance_to_first .race_spectator_dtf_progress .race_spectator_progress .race_spectator_progress_bar .race_spectator_progress_bar_content {
  background-color: green;
  display: inline-block;
  height: 10px;
  font-size: 0;
}
.race_spectator_distance_to_first .race_spectator_dtf_progress .race_spectator_progress .race_spectator_progress_flag_container {
  text-align: center;
  padding-top: 10px;
}
.race_spectator_distance_to_first .race_spectator_dtf_progress .race_spectator_progress .race_spectator_progress_flag_container .race_spectator_progress_flag {
  color: var(--gold);
  font-size: 30px;
  color: 'white';
}

/** / DISTANCE to FIRST / PROGRESS ******************************************************************************/


/** CHART ******************************************************************************/

.race_spectator_header .race_spectator_zoom_setting {
}

.race_spectator_chart .race_spectator_subtitle .race_spectator_chart_zoom_setting {
  display: inline-block;
  max-width: 180px;
  width: 20%;
  vertical-align: bottom;
}

.race_spectator_chart .race_spectator_subtitle .race_spectator_chart_competitors_filtering {
  display: inline-block;
  vertical-align: bottom;
}


/** / CHART ******************************************************************************/

.inject-meeting-page {
    background-color: #262626;
    color: white
}

.inject-meeting-page .header .danger {
    color: red;
    font-weight: bold
}

.inject-meeting-page .header .information {
    color: cyan;
    font-weight: bold
}

.inject-meeting-page .body {
    min-height: 180px;
}

