/* layout */
.private_page_wrapper {
  display: flex;
  height: 100%;
  overflow: auto;  
  padding: 0px;
  justify-content: flex-start;
  flex-direction: column;
}

/* components */
.private_page_wrapper .header {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.private_page_wrapper .data_list_toolbar {
  display: flex;
  justify-content: space-between;
}

.private_page_wrapper .action_toolbar {
  padding: 10px;
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.private_page_wrapper .data_list {
  background-color: #E5E5E5;
  width: 100%;
  height: 100%;
}

/* titles */
.private_page_wrapper .title {
  text-transform: uppercase;
  font-size: 20px;
  font-family: var(--orbitron_font);
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.private_page_wrapper .subtitle {
  font-size: 14px;
  font-family: var(--common_font);
}

/* form */
.common_form {
  max-width: 700px;
}

/* search_filters */
.search_filters {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;  
  margin: 0px 24px;
  justify-content: flex-start;
}

.search_filters_elems {
  min-width: 300px;
  width: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search_filters_title_div {
  width: 90px;
  min-width: 90px;
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
}
.search_filters_select_div {
  width: 95%;
}