.common_list {
  background-color: #E5E5E5;
  border-collapse: separate;
}
.common_list .ant-table-thead .ant-table-cell {
  background-color: #E3E9EE;
  border: 0px solid #DDDDDD;
}

/* col action */
.common_list th.col_action,
.common_list td.col_action {
  text-align: center;
}
.common_list .ant-btn-circle {
  border-radius: 50%;
  margin-right: 3px;
}

/* icones */
.record_active {
  color: green;
  font-size: 25px;
}
.record_disabled {
  color: red;
  font-size: 25px;
}
.record_admin {
  color: var(--gold);
  font-size: 25px;
}
