.user-page {
    background-color: var(--dark_grey);
    color: white;
    --label-width: 150px;
    --label-padding: 5px;
    --column-right-width: 120px;
    --nb-column-right: 8;
    --right-disabled-bgcolor: var(--dark_grey);
    --right-disabled-color: var(--grey);
    --right-unactivate-bgcolor: brown;
    --right-unactivate-color: white; 
    --right-activate-today-bgcolor: green;
    --right-activate-today-color: white;
    --right-activate-other-day-bgcolor: orange;
    --right-activate-other-day-color: white;
    --right-selected-border-radius: 30px;

    --users-data-form-width: 350px;
    --sources-width : calc( var(--label-width) + (var(--column-right-width) * var(--nb-column-right)));
}

.user-page .user-form {
}
.user-page .user-form .user-data-form {
    display: inline-block;
    width: var(--users-data-form-width);
    vertical-align: top;
}
.user-page .user-form .user-form-multidate {
    display: inline-block;
    width: calc( var(--sources-width) - var(--users-data-form-width) );

    vertical-align: bottom;
    padding-bottom: 10px;
    text-align: right;
}
.user-page .user-form .user-form-multidate .user-form-multidate-selections {
    text-align: left;
    max-width: 380px;
    margin-left: auto;
}
.user-page .user-form .user-data-form .user-data-input {
    margin: 10px 0px;
}
.user-page .user-form .user-data-form .user-data-input .label {
    display: inline-block;
    width: var(--label-width);
    text-align: right;
    padding-right: var(--label-padding);
}
.user-page .user-form .user-data-form .user-data-input .label:after {
    content: ':';
}
.user-page .user-form .user-data-form .user-data-input .input {
    display: inline-block;
}
.user-page .user-form .user-data-form .user-data-input .input input {
    background-color: var(--black);
    color: white;
}
.user-page .user-form .user-sources-form {
    width: var(--sources-width);
}
.user-page .user-form .user-sources-form .user-sources-table {
    margin-top: 10px;
}
.user-page .user-form .user-sources-form .user-sources-table .user-sources-header {
    text-align: center;
}
.user-page .user-form .user-sources-form .user-sources-table .user-sources-header .right-level-1 {
    border-top : 1px solid white;
}
.user-page .user-form .user-sources-form .user-sources-table .user-sources-header .right-level-1,
.user-page .user-form .user-sources-form .user-sources-table .user-sources-header .right-level-2,
.user-page .user-form .user-sources-form .user-sources-table .user-sources-header .right-level-3 {
    border-right : 1px solid white;
    border-left : 1px solid white;
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form {
    height: 70px;
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-label {
    width: var(--label-width);
    text-align: right;
    padding-right: var(--label-padding);
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-label:after {
    content: ':';
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input {
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-top: 1px dotted white;
    border-bottom: 1px dotted white;
    width: var(--column-right-width);
    vertical-align: top;
    padding: 0px 10px;
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input.disabled {
    background-color: var(--right-disabled-bgcolor);
    color: var(--right-disabled-color);
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input.activate.other-period {
    background-color: var(--right-activate-other-day-bgcolor);
    color: var(--right-activate-other-day-color);
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input.activate.actual-period {
    background-color: var(--right-activate-today-bgcolor);
    color: var(--right-activate-today-color);
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input.unactivate {
    background-color: var(--right-unactivate-bgcolor);
    color: var(--right-unactivate-color);
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input.selected {
    border-radius: var(--right-selected-border-radius);
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input .user-source-switch {
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input .user-source-date-start {
}
.user-page .user-form .user-sources-form .user-sources-table .user-source-form .user-source-input .user-source-date-stop {
}
.user-page .user-form .user-form-actions {
    padding-top: 20px;
    text-align: center;
}
.user-page .user-form .user-form-actions .user-form-action {
    display: inline-block;
    padding: 20px;
}
.user-page .user-form .user-form-actions .user-form-action .user-form-button {
    width: 200px;
}

/***************************************************************************************************************************************/

.modal-period-choice .ant-modal-header {
    background-color: var(--dark_grey);
}
.modal-period-choice .ant-modal-header .ant-modal-title,
.modal-period-choice .ant-modal-content .ant-modal-close,
.modal-period-choice .period-choice .date-choice .ant-select .ant-select-arrow {
    color: white;
}

.modal-period-choice .ant-modal-content {
    background-color: var(--dark_grey);
    color: white;
}
.modal-period-choice .period-choice {
}
.modal-period-choice .period-choice .date-choice {
    height: 35px;
}
.modal-period-choice .period-choice .date-choice .label,
.modal-period-choice .period-choice .date-choice .year,
.modal-period-choice .period-choice .date-choice .month,
.modal-period-choice .period-choice .date-choice .date {
    display: inline-block;
}
.modal-period-choice .period-choice .date-choice .label {
    width: 50px;
    text-align: right;
    padding-right: 5px;
}
.modal-period-choice .period-choice .date-choice .label:after {
    content: ':';
}
/* .modal-period-choice .period-choice .date-choice .year .ant-select {
    width: 80px;
} */
/* .modal-period-choice .period-choice .date-choice .year .ant-select .ant-select-selector {
    color: red;
    background-color: var(--black);
} */
.modal-period-choice .period-choice .date-choice .ant-select .ant-select-selector {
    background-color: var(--dark_grey);
    border-color: var(--grey);
    color: white;
}
/*.modal-period-choice .period-choice .date-choice*/ .date-choices-list.ant-select-dropdown {
    background-color: var(--dark_grey);
}
/*.modal-period-choice .period-choice .date-choice*/ .date-choices-list.ant-select-dropdown .ant-select-item {
    background-color: var(--dark_grey);
    color: white;
}
/*.modal-period-choice .period-choice .date-choice*/ .date-choices-list.ant-select-dropdown .ant-select-item.ant-select-item-option-active {
    background-color: var(--grey);
    color: white;
}
.modal-period-choice .period-choice .date-choice .year .ant-select {
    /* background-color: var(--dark_grey); */
    width: 80px;
}
.modal-period-choice .period-choice .date-choice .month .ant-select {
    width: 110px;
}
.modal-period-choice .period-choice .date-choice .date .ant-select {
    width: 60px;
}

/***************************************************************************************************************************************/

