/* page ---------------------------------------------------------- */
.race_timeline_wrapper {
  padding: 20px;
  margin: 0px;
  height: 100%;
  position: relative;
  overflow: auto;
  background-color: #262626;
  color: white;
}

/* header -------------------------------------------------------- */
.race_timeline_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  flex-grow: 1;
  padding: 10px;
}
.race_timeline_header h1 {
  color: white;
}
/* header right */
.race_timeline_header_clock {
  display: flex;
}
.race_timeline_header .btn_live {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 10px;
  width: 70px;
  background-color: var(--red);
  font-weight: bold;
  color: white;
}
.race_timeline_header .btn_live:hover {
  background-color: var(--red_hover);
  font-weight: bold;
  color: white;
}
.race_timeline_header_clock span {
  margin: 0 10px 0 15px;
  font-size: 18px;
  font-weight: bold;
}

/* timeline ------------------------------------------------------ */
.race_timeline_content {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  background-color: black;
  border: 1px solid #262626;
}
.race_timeline_hours {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
}
.race_timeline_hours .hour_label {
  height: 60px;
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-right: 1px solid #262626;
}
.race_timeline_hours .hour_label:not(:last-child) {
  border-bottom: 1px solid #262626;
}
.race_timeline_wrapper .time_line {
  height: 2px;
  background-color: red;
  position: absolute;
  width: calc(100% - 140px);
  left: 120px;
}
.race_timeline_header_meeting {
  padding-left: 105px;
  height: 50px;
  display: flex;
}
.race_timeline_header_meeting .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  font-weight: bold;
  background-color: black;
  border-right: 1px solid #262626;
  height: 49px;
}
.race_timeline_meeting {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #262626;
}
.race_block {
  width: 100px;
  height: 25px;
  background-color: #666;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
}
.race_block.active {
  background-color: red;
}


/* popover */
.popover_race_number {
  background-color: red;
  color: white;
  padding: 4px;
  margin-right: 5px;
}
.popover_content_wrapper .race_name {
  font-weight: bold;
}
.popover_content_wrapper p {
  display: flex;
  justify-content: flex-start;
}
.popover_content_wrapper .p_icon {
  width: 30px;
  display: flex;
  justify-content: flex-start;
}
.popover_content_wrapper .p_icon img {
  width: 20px;
}
.popover_content_wrapper .p_label {
  width: 100px;
  display: flex;
  justify-content: flex-start;
}
.popover_content_wrapper .p_val {
  display: flex;
  justify-content: flex-start;
}
