.private_layout .sider {
  background-color: black;
}
.private_layout .sider .logo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.private_layout .sider .logo img {
  width: 90%;
}
/* menu */
.menu_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.private_layout .sider .menu {
  margin-top: 20px;
  background-color: black;
  color: white;
  border: 0px;
}
.private_layout .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
.private_layout .sider .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
  font-size: 25px;
}
.private_layout .sider .menu .ant-menu-item:hover, 
.private_layout .sider .menu .ant-menu-item-active,
.private_layout .sider .menu .ant-menu-item-selected {
  background-color: var(--gold);
  color: white;
}
/* layout */
.private_layout .ant-layout {
  background-color: white;
}

.private_layout_footer {
  text-align: center;
  background-color: #262626;
  padding: 0;
  margin: 0;
  color: white;
  position: fixed;
  bottom: 0;
  left: 50%;
}