.spectator_wrapper {
  background-color: #262626;
  color: white;
  min-height: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  padding: 10px;
}

.spectator_wrapper .race_spectator_subtitle {
  color: var(--gold);
  font-size: 30px;
  margin-top: 15px;
}

.spectator_wrapper .race_spectator_component {
}

/** SWITCHERS ******************************************************************************/

.spectator_wrapper .race_spectator_component .race_spectator_component__switchers {
  display: block;
  position: fixed;
  top: 0px;
  right: 0px;
  border-width: 0px 0px 3px 3px;
  border-style: solid;
  border-color: gold;
  border-radius: 0px 0px 0px 10px;
  padding: 5px;
  background-color: #262626;
}

.spectator_wrapper .race_spectator_component .race_spectator_component__switchers .race_spectator_component__switcher_label {
  display: none;
  margin: 0px 10px;
}

.spectator_wrapper .race_spectator_component .race_spectator_component__switchers:hover .race_spectator_component__switcher_label {
  display: inline-block;
}

/** HEADER ******************************************************************************/
.spectator_wrapper .race_spectator_header {
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.spectator_wrapper .race_spectator_header > .race_spectator_title {
  color: white;
  font-weight: bold;
}
.spectator_wrapper .race_spectator_header > .race_spectator_title > .race_spectator_prefix {
  color: var(--grey);
  font-weight: normal;
  margin-right: 5px;
}
.spectator_wrapper .race_spectator_header > .race_spectator_title > .race_spectator_rc {
  background-color: red;
  padding: 5px;
  border-radius: 6px;
  margin-left: 5px;
  margin-right: 5px;
}
.spectator_wrapper .race_spectator_header > .race_spectator_title > .race_spectator_distance {
  margin-left: 20px;
  color: var(--gold);
}
.spectator_wrapper .race_spectator_header > .race_spectator_title > .race_spectator_discipline {
  margin-left: 5px;
  color: var(--gold);
}
.spectator_wrapper .race_spectator_header > .race_spectator_title > .race_spectator_countdown {
  margin-left: 5px;
  color: var(white);
}

/** /HEADER ******************************************************************************/


/** PERF / DATA ******************************************************************************/

.race_spectator_performances {
}

.race_spectator_performances .race_spectator_performances_block {
  display: inline-block;
}

.race_spectator_performances .race_spectator_performances_data {
  margin-right: 20px;
  vertical-align: top;
}

.race_spectator_performances .race_spectator_performances_data table {
}

.race_spectator_performances .race_spectator_performances_data table thead {
}

.race_spectator_performances .race_spectator_performances_data table thead tr {
}

.race_spectator_performances .race_spectator_performances_data table thead tr th {
  border: 1px solid white;
}
.race_spectator_performances .race_spectator_performances_data table thead tr th.competitors {
  border-top: 0px solid white;
  border-left: 0px solid white;
}
.race_spectator_performances .race_spectator_performances_data table thead tr th.separate {
  border-top-width: 0px;
  border-bottom-width: 0px;
}



.race_spectator_performances .race_spectator_performances_data table tr .column-competitor {
  width: 25px;
}
.race_spectator_performances .race_spectator_performances_data table tr .column-rank,
.race_spectator_performances .race_spectator_performances_data table tr .column-dst,
.race_spectator_performances .race_spectator_performances_data table tr .column-speed,
.race_spectator_performances .race_spectator_performances_data table tr .column-tofirst,
.race_spectator_performances .race_spectator_performances_data table tr .column-sect-rank,
.race_spectator_performances .race_spectator_performances_data table tr .column-sect-speed,
.race_spectator_performances .race_spectator_performances_data table tr .column-sect-time {
  width: 90px;
}
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd .column-sect-time.best,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even .column-sect-time.best {
  background-color: lime;
  font-weight: bolder;
}
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd .column-sect-time.worst,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even .column-sect-time.worst {
  /* color: red; */
}
.race_spectator_performances .race_spectator_performances_data table tr .separate {
  width: 20px;
}

.race_spectator_performances .race_spectator_performances_data table tbody tr {
}

.race_spectator_performances .race_spectator_performances_data table tbody tr td.cell-waiting {
  border: 1px solid white;
  text-align: center;
  font-size: xxx-large;
}

.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even td {
  border: 1px solid white;
  background-color: white;
  color: black;
}
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd td {
  border: 1px solid white;
  background-color: lightgrey;
  color: black;
}
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even.competitor-first td.column-rank,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even.competitor-first td.column-dst,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even.competitor-first td.column-speed,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even.competitor-first td.column-tofirst,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd.competitor-first td.column-rank,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd.competitor-first td.column-dst,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd.competitor-first td.column-speed,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd.competitor-first td.column-tofirst {
  border: 1px solid yellow;
  background-color: yellow;
  color: black;
}

.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-even td.separate,
.race_spectator_performances .race_spectator_performances_data table tbody tr.competitor.competitor-odd td.separate {
  border-top-width: 0px;
  border-bottom-width: 0px;
  background-color: transparent;
}

.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-competitor,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-rank,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-dst,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-speed,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-tofirst,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-sect-rank,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-sect-speed,
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-sect-time {
  text-align: center;
}

.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-competitor .competitor-jersey {
  border-radius: 12.5px;
  border-width: 2px;
  border-style: solid;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-tofirst.near-first {
  color: red;
}
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-sect-rank.big-loose {
  color: red;
}
.race_spectator_performances .race_spectator_performances_data table tbody tr td.column-sect-rank.big-win {
  color: green;
}

.race_spectator_performances .race_spectator_performances_map {
}

.race_spectator_performances .race_spectator_performances_map .race_spectator_performances_map_waiting {
  width: 400px;
  height: 300px;
  border: 1px solid white;
  vertical-align: middle;
  text-align: center;
  line-height: 300px;
  font-size: xx-large;
}

/** /PERF / DATA ******************************************************************************/


/** DISTANCE to FIRST ******************************************************************************/
.race_spectator_distance_to_first {
}
.race_spectator_distance_to_first > div {
  /* border: 1px solid cyan; */
  display: flex;
}
.race_spectator_distance_to_first > div > .race_spectator_subtitle {
  /* border: 1px solid white; */
}
.race_spectator_distance_to_first > div > .race_spectator_dtf_block {
  display: inline-block;
}
.race_spectator_distance_to_first > div > .race_spectator_dtf_block.race_spectator_dtf_herd {
  /* border: 1px solid red; */
}
.race_spectator_distance_to_first > div > .race_spectator_dtf_block.race_spectator_dtf_progress {
  /* border: 1px solid lime; */
}
/** / DISTANCE to FIRST ******************************************************************************/

/** DISTANCE to FIRST / HERD ******************************************************************************/
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line {
  /* border: 1px solid pink; */
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .title {
  display: inline-block;
  padding-right: 20px;
  font-style: italic;
  font-size: 11px;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend {
  display: inline-block;
  font-size: 12px;
  border: 1px solid white;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend .item {
  display: inline-block;
  padding: 0px 20px;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend input {
  background-color: var(--grey);
  width: 20px;
  border-width: 0px;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend .speed-delta-0,
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend .speed-delta-1,
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend .speed-delta-2,
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_title_line .legend .speed-delta-3 {
  font-size: 18px;
  vertical-align: middle;
}
.race_spectator_dtf_herd_lines {
  display: flex;
}

.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_horse_lines {
  /* border: 1px solid lime; */
  display: inline-block;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_horse_lines .race_spectator_dtf_herd_horse_line {
  border-bottom: 1px solid grey;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_horse_lines .race_spectator_dtf_herd_horse_line.waiting {
  height: 125px;
  text-align: center;
  vertical-align: middle;
  font-size: xxx-large;
  line-height: 125px;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_horse_lines .race_spectator_dtf_herd_horse_line .competitor-jersey {
  border-radius: 12.5px;
  border-width: 2px;
  border-style: solid;
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .speed-delta-0 {
  color: transparent;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .speed-delta-1 {
  color: blue;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .speed-delta-2 {
  color: gold;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .speed-delta-3 {
  color: red;
}
.race_spectator_distance_to_first .race_spectator_dtf_herd .race_spectator_dtf_herd_final {
  display: inline-block;
  /* border: 1px solid tan; */
  border-left: 2px dashed white;
}

/** / DISTANCE to FIRST / HERD ******************************************************************************/

/** DISTANCE to FIRST / PROGRESS ******************************************************************************/
.race_spectator_distance_to_first .race_spectator_dtf_progress .race_spectator_progress {
  padding: 70px 10px 0px 10px;
}
.race_spectator_distance_to_first .race_spectator_dtf_progress .race_spectator_progress .race_spectator_progress_pct {
  text-align: center;
  padding-bottom: 10px;
  font-size: 40px;
  color: var(--gold);
}
.race_spectator_distance_to_first .race_spectator_dtf_progress .race_spectator_progress .race_spectator_progress_bar {
  width : 100px;
  height: 10px;
  border: 1px solid var(--gold);
  display: flex;
  box-sizing: content-box;
}
.race_spectator_distance_to_first .race_spectator_dtf_progress .race_spectator_progress .race_spectator_progress_bar .race_spectator_progress_bar_content {
  background-color: green;
  display: inline-block;
  height: 10px;
  font-size: 0;
}
.race_spectator_distance_to_first .race_spectator_dtf_progress .race_spectator_progress .race_spectator_progress_flag_container {
  text-align: center;
  padding-top: 10px;
}
.race_spectator_distance_to_first .race_spectator_dtf_progress .race_spectator_progress .race_spectator_progress_flag_container .race_spectator_progress_flag {
  color: var(--gold);
  font-size: 30px;
  color: 'white';
}

/** / DISTANCE to FIRST / PROGRESS ******************************************************************************/


/** CHART ******************************************************************************/

.race_spectator_header .race_spectator_zoom_setting {
}

.race_spectator_chart .race_spectator_subtitle .race_spectator_chart_zoom_setting {
  display: inline-block;
  max-width: 180px;
  width: 20%;
  vertical-align: bottom;
}

.race_spectator_chart .race_spectator_subtitle .race_spectator_chart_competitors_filtering {
  display: inline-block;
  vertical-align: bottom;
}


/** / CHART ******************************************************************************/
